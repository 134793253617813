@-webkit-keyframes rotating {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes rotating {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.loading-spinner-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, .6);
    z-index: 10001
}

.loading-spinner-popup,
.loading-spinner-popup__container {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.loading-spinner-popup__container {
    width: 108px;
    height: 108px;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .09);
    border-radius: 4px
}

.loading-spinner-popup__container img {
    -webkit-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite
}

.shopee-modal__transition-appear {
    opacity: .01
}

.shopee-modal__transition-appear.shopee-modal__transition-appear-active {
    opacity: 1;
    transition: opacity .2s
}

.shopee-modal__transition-enter {
    opacity: .01
}

.shopee-modal__transition-enter.shopee-modal__transition-enter-active {
    opacity: 1;
    transition: opacity .2s
}

.shopee-modal__transition-exit {
    opacity: 1
}

.shopee-modal__transition-exit.shopee-modal__transition-exit-active {
    opacity: .01;
    transition: opacity .2s
}

._1SgD8G {
    height: 18.75rem;
    margin: 12.5rem auto 1.25rem;
    display: block
}

.Sm2Q4C {
    font-size: 1rem;
    color: #222
}

._3DtRKO,
.Sm2Q4C {
    text-align: center
}

._3DtRKO {
    border: .0625rem solid rgba(0, 0, 0, .14);
    border-radius: .625rem;
    margin: .625rem;
    padding: .625rem
}

._3sjFp {
    border-radius: .125rem;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: -moz-inline-box;
    display: inline-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -moz-box-pack: center;
    justify-content: center;
    padding: 0 1rem;
    overflow: hidden;
    outline: none;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -moz-box-flex: 0;
    flex: 0 0 auto;
    position: relative
}

._3sjFp:active:before {
    content: "";
    background-color: rgba(0, 0, 0, .05);
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0
}

.lJMau {
    height: 2.75rem
}

._3zn07 {
    height: 2.25rem;
    min-width: 4rem
}

._18F8b {
    height: 1.75rem;
    min-width: 4rem;
    padding: 0 .75rem
}

.uxSdp {
    height: 1.25rem;
    min-width: 3.25rem;
    padding: 0 .5rem
}

.xCn81 {
    background: transparent
}

.TOjHY.AHhqR {
    color: #fff;
    border: .0625rem solid transparent;
    background: #ee4d2d;
    background: var(--nc-primary, #ee4d2d)
}

.TOjHY.xCn81 {
    color: #ee4d2d;
    color: var(--nc-primary, #ee4d2d);
    border: .0625rem solid #ee4d2d;
    border: .0625rem solid var(--nc-primary, #ee4d2d)
}

.TOjHY.xCn81:active:before {
    background-color: #ee4d2d;
    background-color: var(--nc-primary, #ee4d2d);
    opacity: .05
}

._1-4Xn.xCn81 {
    color: rgba(0, 0, 0, .87);
    color: var(--nc-text-primary, rgba(0, 0, 0, .87));
    border: .0625rem solid rgba(0, 0, 0, .26)
}

._1qQAl.xCn81 {
    color: #fff;
    border: .0625rem solid #fff
}

._1qQAl.xCn81:active:before {
    background-color: #fff;
    opacity: .26
}

._3sjFp:disabled {
    color: rgba(0, 0, 0, .26);
    color: var(--nc-util-disabled, rgba(0, 0, 0, .26))
}

._3sjFp:disabled.xCn81 {
    border-color: rgba(0, 0, 0, .09);
    border-color: var(--nc-util-line, rgba(0, 0, 0, .09))
}

._3sjFp:disabled._1qQAl.xCn81 {
    border-color: hsla(0, 0%, 100%, .54);
    color: hsla(0, 0%, 100%, .4)
}

._3sjFp:disabled.TOjHY.AHhqR {
    background: rgba(0, 0, 0, .09)
}

._2HCeY {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

._19rDu {
    margin-left: .25rem
}

._164M6a {
    transition: margin-top .3s cubic-bezier(.4, 0, .2, 1);
    -webkit-overflow-scrolling: touch
}

._164M6a._2moZ5O {
    margin-top: 7.4375rem
}

._164M6a.dI2ZmV {
    margin-top: 11.125rem
}

._2cf99j {
    background-color: #fffefb;
    border: 1px solid rgba(224, 168, 0, .4);
    padding: .75rem 1rem;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .625rem;
    border-radius: 2px
}

._1NxVa_ {
    width: unset
}

._1Xcrmf {
    color: #222;
    margin-left: .5rem
}

._16wFSv {
    color: #05a
}

._1c5iEl.pd_la2 {
    font-size: .625rem;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    width: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content
}

._1c5iEl {
    position: relative;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    white-space: nowrap;
    border-style: solid;
    border-width: .0625rem 0;
    color: #ee4d2d;
    margin-left: .5rem;
    padding: 0 .3125rem;
    font-size: .75rem;
    height: 1.1875rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: default
}

._3xW6Ck {
    color: #9b9b9b
}

._1c5iEl span {
    text-overflow: ellipsis;
    overflow: hidden
}

._2JMrQa,
._2JRAUv {
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    overflow: hidden
}

._2JMrQa svg,
._2JRAUv svg {
    width: 5px;
    height: 35px;
    fill: #ee4d2d
}

._2JRAUv {
    left: 0
}

._2JMrQa {
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

._2JMrQa svg {
    float: right
}

._25AeXf {
    height: .75rem;
    margin-right: .1875rem;
    margin-left: -.1875rem
}

.kLBApN {
    color: #ee4d2d
}

._2waIDV {
    font-size: .875rem;
    color: #757575
}

.U6m2Bl {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.shopee-popover--default,
.shopee-popover--dropdown {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: 2px;
    background: #fff
}

.shopee-popover--default {
    max-height: 8.375rem;
    padding: .3125rem 0;
    overflow-x: hidden;
    overflow-y: auto
}

.shopee-popover--dropdown {
    border: 1px solid rgba(0, 0, 0, .09)
}

.shopee-popover--dropdown:after,
.shopee-popover--dropdown:before {
    content: "";
    position: absolute;
    right: 1rem
}

.shopee-popover--dropdown:after {
    border: .5rem solid transparent;
    border-bottom-color: #fff;
    top: -.9375rem;
    z-index: 1
}

.shopee-popover--dropdown:before {
    border: .5rem solid transparent;
    border-bottom-color: rgba(0, 0, 0, .09);
    top: -1.0625rem;
    z-index: 0
}

.stardust-popover {
    position: relative
}

.stardust-popover__popover {
    will-change: transform;
    position: absolute;
    z-index: -1
}

.stardust-popover__popover--show {
    z-index: 3;
    -webkit-animation: popover-enter-animation .2s cubic-bezier(.4, 0, .6, 1);
    animation: popover-enter-animation .2s cubic-bezier(.4, 0, .6, 1);
    opacity: 1
}

.stardust-popover__popover--border {
    border: 1px solid rgba(0, 0, 0, .09)
}

.stardust-popover__popover--hide {
    -webkit-animation: popover-hide-animation .2s cubic-bezier(.4, 0, .6, 1);
    animation: popover-hide-animation .2s cubic-bezier(.4, 0, .6, 1)
}

.stardust-popover__arrow {
    z-index: 3;
    position: absolute;
    will-change: transform
}

.stardust-popover__arrow--inner {
    position: absolute;
    left: -8px;
    width: 0;
    height: 0
}

@-webkit-keyframes popover-enter-animation {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes popover-enter-animation {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes popover-hide-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    to {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }
}

@keyframes popover-hide-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    to {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }
}

._2gJN5P {
    grid-column-start: 2;
    grid-column-end: 4;
    -ms-grid-column-span: 2;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .75rem 0
}

._3pnL2v {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 1.875rem
}

._2gJN5P .icon-voucher-applied-line,
._2gJN5P .icon-voucher-line {
    color: #ee4d2d;
    fill: currentColor;
    width: 1.3125rem;
    height: 1.25rem
}

._1wcxd3 {
    width: 1.125rem;
    height: 1.125rem;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    overflow: visible !important
}

._1D0Wmy,
._2YRnUZ {
    font-weight: 500;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 .5rem
}

._2YRnUZ {
    margin: 0 .3125rem
}

._3fKJp8 {
    margin-right: 1.875rem;
    text-transform: capitalize;
    color: #05a;
    font-size: .875rem;
    white-space: nowrap;
    cursor: pointer
}

._4WXRJn {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._1W_RQk,
.J4KzWR {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.J4KzWR {
    margin-top: .5rem
}

.xlrGMs {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #ee4d2d;
    font-size: .875rem;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.xlrGMs .shopee-drawer {
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

._13HpQ7 {
    margin-left: .75rem;
    color: #05a;
    font-weight: medium;
    font-size: .875rem;
    white-space: nowrap;
    cursor: pointer
}

._1TYYUl {
    background: #fff;
    color: rgba(0, 0, 0, .8);
    width: 18.75rem;
    padding: 1.25rem;
    font-size: .875rem;
    line-height: 1.375rem;
    word-wrap: break-word;
    text-align: left
}

._1hCNHY {
    stroke: currentColor;
    color: rgba(0, 0, 0, .54);
    margin-left: .375rem
}

._3acqDm {
    color: #ee4d2d
}

.stardust-checkbox {
    font-family: -apple-system, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 300;
    font-size: 12px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    position: relative;
    color: rgba(0, 0, 0, .54);
    max-width: 400px
}

.stardust-checkbox__input {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute
}

.stardust-checkbox__box {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    width: 16px;
    height: 16px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .14);
    text-align: center;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, .02);
    margin-right: 8px
}

.stardust-checkbox__box:hover {
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .09)
}

.stardust-checkbox__box:before {
    position: absolute;
    content: "";
    height: 5px;
    width: 9px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 3px;
    top: 3px
}

.stardust-checkbox--disabled {
    opacity: .35;
    cursor: no-drop
}

.stardust-checkbox--disabled .stardust-checkbox__box {
    background: #e7e7e7 !important;
    box-shadow: none !important
}

.stardust-checkbox--disabled .stardust-checkbox__box:before {
    border-color: transparent
}

.stardust-checkbox--checked>.stardust-checkbox__box {
    background: #ee4d2d;
    border-color: #ee4d2d
}

.stardust-checkbox--checked>.stardust-checkbox__box:hover {
    box-shadow: none
}

.stardust-checkbox--checked>.stardust-checkbox__box:before {
    border-color: #fff
}

.DOOM4N {
    font-size: 14px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.aWYRrb ._1hOsmP,
.aWYRrb ._1nDYM0,
.aWYRrb ._2VzSPd {
    opacity: .2
}

.aWYRrb ._2VzSPd {
    cursor: not-allowed
}

._2VzSPd {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    min-width: 58px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    grid-column-start: 1;
    grid-column-end: 2;
    -ms-grid-column-span: 1;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 16px 12px 16px 20px
}

._2VzSPd .stardust-checkbox--disabled {
    opacity: 1
}

._2VzSPd .stardust-checkbox--disabled .stardust-checkbox__box {
    border: 1px solid #e7e7e7
}

._1nDYM0 {
    width: 18px;
    height: 18px;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    fill: none
}

._1hOsmP {
    font-weight: 500;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 8px
}

._1WYjnM {
    font-weight: 500;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    color: #929292;
    margin-left: 17px
}

._5Io674 {
    z-index: 2
}

._21ZtEX {
    stroke: currentColor;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-size: 12px;
    color: rgba(0, 0, 0, .54);
    padding-right: 6px;
    padding-left: 6px
}

._1LLQ50 {
    background: #fff;
    color: rgba(0, 0, 0, .8);
    width: 300px;
    padding: 20px;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
    text-align: left
}

.z2gYIU {
    padding-right: 30px;
    font-weight: 500;
    color: #d0d0d0
}

._2E5uuf {
    color: #ee4d2d
}

._8tayl2 {
    grid-column-start: 3;
    grid-column-end: 4;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

._2gEAGZ,
._8tayl2 {
    -ms-grid-column-span: 1;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._2gEAGZ {
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    grid-column-start: 2;
    grid-column-end: 3
}

.stardust-toast {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: opacity .1s cubic-bezier(.4, 0, .6, 1);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: all
}

.stardust-toast--hidden {
    opacity: 0;
    pointer-events: none
}

.stardust-toast__container {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    background-color: rgba(0, 0, 0, .65);
    color: #fff;
    text-align: center;
    padding: 15px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    pointer-events: all;
    cursor: default;
    border-radius: 7px;
    pointer-events: inherit
}

.stardust-toast__icon svg {
    width: 30px;
    height: 30px;
    margin-bottom: 7px
}

.shopee-button-solid {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1);
    position: relative;
    background: rgba(0, 0, 0, .54);
    color: #fff;
    transition: opacity .2s ease;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .09)
}

.shopee-button-solid:active {
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.shopee-button-solid--fill {
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0
}

.shopee-button-solid--complement {
    background: #00bfa5
}

.shopee-button-solid--complement:hover {
    background: #00a08a
}

.shopee-button-solid--secondary {
    background: #3b3f53
}

.shopee-button-solid--primary {
    background: #ee4d2d
}

.shopee-button-solid--primary:not(.shopee-button-solid--disabled):hover {
    background: #f05d40
}

.shopee-button-solid--primary:not(.shopee-button-solid--disabled):active,
.shopee-button-solid--primary:not(.shopee-button-solid--disabled):focus {
    background: #e2492b;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.shopee-button-solid--primary.shopee-button-solid--disabled {
    background: #facac0 !important;
    cursor: not-allowed;
    box-shadow: none
}

.shopee-button-solid--tributton {
    padding: 10px 13px !important;
    -webkit-flex: none !important;
    -moz-box-flex: 0 !important;
    -ms-flex: none !important;
    flex: none !important;
    text-transform: uppercase !important;
    width: 102px;
    height: 36px;
    background: #ee4d2d
}

.shopee-button-solid--tributton:not(.disabled):hover {
    background: #f05d40
}

.shopee-button-solid--tributton:not(.disabled):active,
.shopee-button-solid--tributton:not(.disabled):focus {
    background: #e2492b;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.shopee-button-solid--tributton.disabled {
    background: #facac0 !important;
    cursor: not-allowed;
    box-shadow: none
}

.shopee-button-solid--email {
    background-color: #1d9db4
}

.shopee-button-solid--facebook {
    background-color: #31589c
}

.shopee-button-solid--sms {
    background-color: #13b50b
}

.shopee-button-solid--disabled {
    box-shadow: none;
    background-color: rgba(0, 0, 0, .26)
}

.shopee-button-solid--disabled:hover {
    background-color: rgba(0, 0, 0, .26);
    cursor: not-allowed
}

.shopee-button-outline {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1);
    border: 1px solid rgba(0, 0, 0, .09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0, 0, 0, .8)
}

.shopee-button-outline--normal {
    background: #fff
}

.shopee-button-outline--normal:hover {
    background: #f3f3f3
}

.shopee-button-outline--disabled {
    color: rgba(0, 0, 0, .26);
    border-color: rgba(0, 0, 0, .09)
}

.shopee-button-outline--fill {
    width: 100%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0
}

.shopee-button-outline--tributton {
    text-transform: uppercase !important;
    width: 130px;
    height: 36px
}

.shopee-button-outline--tributton,
.shopee-button-outline--tributton:hover {
    -webkit-flex: none !important;
    -moz-box-flex: 0 !important;
    -ms-flex: none !important;
    flex: none !important;
    padding: 10px 15px !important
}

.shopee-button-outline--primary-reverse,
.shopee-button-outline--primary:hover,
.shopee-button-outline--tributton:hover {
    color: #ee4d2d;
    border-color: #ee4d2d
}

.shopee-button-outline--primary-reverse:hover {
    background: #ee4d2d;
    color: #fff
}

.shopee-button-outline--complement:hover {
    color: #00bfa5;
    border-color: #00bfa5
}

.shopee-button-outline--rounded {
    border-radius: 1.25rem
}

.shopee-button-outline--rounded:hover {
    color: #ee4d2d;
    border-color: #ee4d2d
}

.cancel-btn {
    padding: 10px 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 140px;
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 400;
    cursor: pointer;
    color: #555;
    margin-right: 6px;
    line-height: 1;
    background: none;
    border: 0;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1)
}

.cancel-btn:hover {
    background: #f8f8f8
}

.cancel-btn:active {
    background: #f5f5f5
}

.shopee-button-no-outline,
.shopee-icon-button,
.shopee-image-button {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1)
}

.shopee-button-no-outline {
    padding: .3125rem .4375rem;
    color: rgba(0, 0, 0, .8);
    background-color: transparent;
    border: 0
}

.shopee-button-no-outline--primary,
.shopee-button-no-outline:hover {
    color: #ee4d2d
}

.shopee-button-no-outline--complement {
    color: #00bfa5
}

.shopee-button-no-outline--disabled {
    color: rgba(0, 0, 0, .26);
    cursor: default
}

.shopee-button-no-outline--disabled:hover {
    color: rgba(0, 0, 0, .26)
}

.shopee-button-no-outline--tributton {
    color: rgba(0, 0, 0, .87);
    -webkit-flex: none !important;
    -moz-box-flex: 0 !important;
    -ms-flex: none !important;
    flex: none !important;
    padding: 10px 15px !important;
    text-transform: uppercase;
    height: 36px
}

.shopee-button-no-outline--tributton:hover {
    color: #ee4d2d
}

.shopee-icon-button {
    border: 1px solid rgba(0, 0, 0, .54);
    background-color: transparent;
    padding: 0
}

.shopee-image-button {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100%;
    position: relative
}

.shopee-image-button:hover .shopee-image-button__mask {
    background-color: rgba(0, 0, 0, .7)
}

.shopee-image-button__mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .4)
}

.shopee-image-button__text {
    color: #fff;
    font-weight: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 .625rem;
    width: 100%
}

.shopee-see-all-button>.shopee-button-no-outline {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ee4d2d
}

.shopee-see-all-button>.shopee-button-no-outline>.icon-arrow-right {
    font-size: .625rem
}

.stardust-button {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .26);
    background-color: #fff;
    color: rgba(0, 0, 0, .87);
    padding: 8px 10px
}

.stardust-button.stardust-button--active,
.stardust-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .09);
    border-color: rgba(0, 0, 0, .26)
}

.stardust-button--disabled {
    background-color: rgba(0, 0, 0, .26);
    border: 1px solid rgba(0, 0, 0, .26)
}

.stardust-button--disabled.stardust-button--active,
.stardust-button--disabled:hover {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, .26);
    border: 1px solid rgba(0, 0, 0, .26)
}

.stardust-button--block {
    display: block;
    width: 100%
}

.stardust-button--block+.stardust-button--block {
    margin-top: 5px
}

.stardust-button--large {
    font-size: 16px;
    border-radius: 6px;
    padding: 12px 14px
}

.stardust-button--large,
.stardust-button--small {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400
}

.stardust-button--small {
    font-size: 12px;
    border-radius: 3px;
    padding: 6px 8px
}

.stardust-button--primary {
    background-color: #ee4d2d;
    border-color: #cd3011;
    color: #fff
}

.stardust-button--primary.stardust-button--active,
.stardust-button--primary:hover {
    background-color: #d73211;
    border-color: #ba2b0f
}

.stardust-button--primary.stardust-button--disabled {
    opacity: .7
}

.stardust-button--primary.stardust-button--active.stardust-button--disabled,
.stardust-button--primary:hover.stardust-button--disabled {
    background-color: #ee4d2d;
    border-color: #cd3011
}

.stardust-button--warning {
    background-color: #ff424f;
    border-color: #ef0010;
    color: #fff
}

.stardust-button--warning.stardust-button--active,
.stardust-button--warning:hover {
    background-color: #fa0011;
    border-color: #e50010
}

.stardust-button--warning.stardust-button--disabled {
    opacity: .7
}

.stardust-button--warning.stardust-button--active.stardust-button--disabled,
.stardust-button--warning:hover.stardust-button--disabled {
    background-color: #ff424f;
    border-color: #ef0010
}

.stardust-button--outline-primary {
    background-color: transparent;
    border-color: #ee4d2d;
    color: #ee4d2d
}

.stardust-button--outline-primary.stardust-button--active,
.stardust-button--outline-primary:hover {
    background-color: hsla(0, 0%, 100%, .3);
    border-color: #d73211
}

.stardust-button--outline-primary.stardust-button--disabled {
    opacity: .7
}

.stardust-button--outline-primary.stardust-button--active.stardust-button--disabled,
.stardust-button--outline-primary:hover.stardust-button--disabled {
    background-color: transparent;
    border-color: #ee4d2d
}

.stardust-button--outline-white {
    background-color: transparent;
    border-color: #fff;
    color: #fff
}

.stardust-button--outline-white.stardust-button--active,
.stardust-button--outline-white:hover {
    background-color: hsla(0, 0%, 100%, .3);
    border-color: #fff
}

.stardust-button--outline-white.stardust-button--disabled {
    opacity: .7
}

.stardust-button--outline-white.stardust-button--active.stardust-button--disabled,
.stardust-button--outline-white:hover.stardust-button--disabled {
    background-color: transparent;
    border-color: #fff
}

.stardust-button--ghost-primary {
    background-color: transparent;
    border-color: transparent;
    color: #ee4d2d
}

.stardust-button--ghost-primary.stardust-button--active,
.stardust-button--ghost-primary:hover {
    background-color: rgba(0, 0, 0, .09);
    border-color: rgba(0, 0, 0, .09)
}

.stardust-button--ghost-primary.stardust-button--disabled {
    opacity: .7
}

.stardust-button--ghost-primary.stardust-button--active.stardust-button--disabled,
.stardust-button--ghost-primary:hover.stardust-button--disabled {
    background-color: transparent;
    border-color: transparent
}

._2TnlBU {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    padding: 2.75rem 2.5rem 1.5rem;
    width: 27.5rem
}

._3RgXWe {
    line-height: 1.125rem;
    font-size: 1rem;
    color: #333
}

.EUd_G5 {
    margin-top: 2.1875rem;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.EUd_G5 .cancel-btn,
.EUd_G5 .shopee-button-solid--confirm-popup {
    width: 6.5625rem;
    min-height: 2.5rem;
    font-size: .875rem
}

.EUd_G5 .shopee-button-solid--confirm-popup {
    text-transform: uppercase;
    margin-right: 1.5rem
}

.confirm-popup {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.confirm-popup,
.confirm-popup__button-group {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.confirm-popup__button-group {
    margin-top: 2rem
}

.confirm-popup .shopee-button-outline--confirm-popup,
.confirm-popup .shopee-button-solid--confirm-popup {
    width: 6.25rem;
    min-height: 2.5rem;
    font-size: .875rem;
    text-transform: uppercase;
    margin: .46875rem
}

.confirm-two-popup {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.confirm-two-popup,
.confirm-two-popup__button-group {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

.confirm-two-popup__button-group {
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 1.8125rem;
    width: 100%
}

.confirm-two-popup .shopee-button-no-outline--confirm-two-popup,
.confirm-two-popup .shopee-button-solid--confirm-two-popup {
    width: 7.5rem;
    min-height: 2.5rem;
    font-size: .875rem;
    text-transform: uppercase;
    padding: .75rem;
    line-height: 1rem;
    font-weight: 500;
    font-family: Roboto
}

.confirm-two-popup .shopee-button-no-outline--confirm-two-popup {
    margin-right: 1.25rem;
    color: #000;
    opacity: .54
}

._3eZy4w {
    position: absolute;
    top: 0;
    left: 0
}

._3fCPAy {
    width: 3.625rem
}

._2zXhtr {
    padding: 1.875rem 1.875rem 1.25rem;
    width: 34.375rem;
    position: relative;
    overflow: hidden;
    border-radius: .1875rem;
    background-color: #fff;
    box-shadow: 0 .1875rem .625rem 0 rgba(0, 0, 0, .14);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.f-4R3V {
    margin-bottom: .5625rem;
    font-size: 1.375rem;
    font-weight: 600;
    color: #333
}

._3LjKcY {
    margin-bottom: 1.625rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #333
}

.TZHXRx {
    padding-right: 2.4375rem
}

._2Y0OD5 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._3KM-V9 {
    overflow: hidden;
    margin-bottom: .6875rem
}

._17Agzc {
    margin: -.625rem -.3125rem -.9375rem;
    width: 10rem;
    height: 10rem
}

._3IWSm0,
.R4NrO- {
    width: 9.625rem;
    height: 3.1875rem;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: .625rem
}

.R4NrO- {
    background-position-x: -.625rem;
    background-position-y: -.6875rem;
    background-size: 113% 146%
}

._3IWSm0 {
    background-size: 100%
}

.lUjLg6 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.aaKW6H,
.x5Q-wH {
    height: 1rem;
    padding: .1875rem
}

.icon-popup {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.error-popup .icon-error {
    margin-bottom: 14px;
    width: 50px;
    height: 50px
}

.btn {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 14px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .09);
    border-radius: 2px;
    border: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer
}

.btn--inline {
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.btn--full {
    width: 100%
}

.btn--s {
    height: 34px;
    padding: 0 15px;
    min-width: 60px;
    max-width: 190px
}

.btn--m {
    height: 40px;
    padding: 0 20px;
    min-width: 70px;
    max-width: 220px
}

.btn--l {
    font-size: 16px;
    height: 48px;
    padding: 0 20px
}

.btn--l,
.btn--xl {
    min-width: 80px;
    max-width: 250px
}

.btn--xl {
    height: 52px
}

.btn-solid-primary {
    color: #fff;
    background: #ee4d2d
}

.btn-solid-primary:not(.btn-solid-primary--disabled):hover {
    background: #f05d40
}

.btn-solid-primary:not(.btn-solid-primary--disabled):active,
.btn-solid-primary:not(.btn-solid-primary--disabled):focus {
    background: #e2492b;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.btn-solid-primary.btn-solid-primary--disabled {
    background: #facac0 !important;
    cursor: not-allowed;
    box-shadow: none
}

.btn-solid-primary--link {
    color: #fff
}

.theme--ofs .btn-solid-primary {
    background: #d0011b
}

.theme--ofs .btn-solid-primary:not(.btn-solid-primary--disabled):hover {
    background: #d41830
}

.theme--ofs .btn-solid-primary:not(.btn-solid-primary--disabled):active,
.theme--ofs .btn-solid-primary:not(.btn-solid-primary--disabled):focus {
    background: #c6011a;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.theme--ofs .btn-solid-primary.btn-solid-primary--disabled {
    background: #f1b3bb !important;
    cursor: not-allowed;
    box-shadow: none
}

.theme--s-mart .btn-solid-primary {
    background: #066bc8
}

.theme--s-mart .btn-solid-primary:not(.btn-solid-primary--disabled):hover {
    background: #1c78cd
}

.theme--s-mart .btn-solid-primary:not(.btn-solid-primary--disabled):active,
.theme--s-mart .btn-solid-primary:not(.btn-solid-primary--disabled):focus {
    background: #0666be;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.theme--s-mart .btn-solid-primary.btn-solid-primary--disabled {
    background: #b4d3ef !important;
    cursor: not-allowed;
    box-shadow: none
}

.btn-light {
    background: #fff;
    color: #555;
    border: 1px solid rgba(0, 0, 0, .09);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .03)
}

.btn-light:active,
.btn-light:hover {
    background: rgba(0, 0, 0, .02)
}

.btn-light:active {
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.btn-light--link {
    color: #555
}

.btn-light--disabled {
    background: #fff !important;
    color: #ccc;
    cursor: not-allowed
}

.info-popup {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5)
}

.info-popup__head {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px
}

.info-popup__head .shopee-svg-icon {
    height: 24px;
    width: 24px
}

.info-popup__icon {
    margin-right: 10px
}

.info-popup__title {
    font-size: 18px;
    color: #333
}

.info-popup__message {
    font-size: 16px;
    color: #555
}

.info-popup__buttons {
    margin-top: 25px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%
}

.info-popup__btn {
    width: 6.25rem;
    min-height: 2.5rem;
    font-size: .875rem;
    text-transform: uppercase;
    margin: .46875rem
}

.success-popup__icon {
    margin-bottom: 14px;
    width: 50px;
    height: 50px
}

.stardust-icon {
    stroke: currentColor;
    fill: currentColor;
    width: 1em;
    height: 1em
}

.shopee-alert-popup {
    background-color: #fff
}

.shopee-alert-popup__btn {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 100%
}

.shopeeCopyMessage {
    width: 100%;
    text-align: center;
    margin-top: 44px;
    font-size: 20px;
    margin-bottom: 66px;
    outline: none;
    background-color: transparent;
    border: 0 solid;
    color: #f5a623
}

.shopeeTributtonTitle {
    color: #555;
    font-size: 18px;
    margin-top: 6px;
    margin-left: 10px
}

.shopeeTributtonButtons {
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 0
}

.kcsswk {
    width: 100%
}

.Ds51ip {
    width: 550px;
    padding: 0 30px;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .14);
    border-radius: 3px
}

._2Iq2fy {
    padding: 25px 0;
    border-bottom: .5px solid rgba(0, 0, 0, .09);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px
}

._2_qXyM,
._2Iq2fy {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

._2_qXyM {
    padding: 15px 0
}

._2_qXyM:not(:last-child) {
    border-bottom: .5px solid rgba(0, 0, 0, .09)
}

._1xeBS3 {
    padding-bottom: 14px
}

.bBd1_7 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    line-height: 17px
}

.bBd1_7:not(:last-child) {
    margin-bottom: 8px
}

.WXADFe {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._2QxU8F {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

._1eO57D,
._3DXt0I {
    font-weight: 500
}

._3DXt0I {
    color: #ee4c2c
}

.RBQ1nj {
    font-weight: 500
}

._1rf1RN {
    color: #f2994a
}

._2mcwsu {
    padding: 2px 0 11px;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    line-height: 14px;
    text-align: right
}

._2BT_es {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._2BT_es,
._3BPMNN {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._3BPMNN {
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

._1TwgPm {
    font-size: 14px;
    margin-top: 1px;
    text-align: right
}

._2Ml26n {
    padding-left: 24px;
    color: #ee4d2d
}

._1CWoDA {
    color: #f6a700
}

._2LMWss {
    margin-left: 20px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._10A7e2 {
    font-size: 16px;
    color: #222;
    line-height: 19px
}

.nBHs8H {
    font-size: 24px;
    line-height: 28px;
    margin-left: 5px;
    color: #ee4d2d
}

.qsL6JH {
    margin-left: 8px
}

.W2HjBQ {
    padding: 12px 0;
    grid-column-start: 1;
    grid-column-end: 4;
    -ms-grid-column-span: 3;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%
}

.j9RJQY {
    margin: 0 8px
}

.mtlnEE {
    color: #bbb
}

._2mPWt7 {
    color: #ee4d2d;
    margin: 0 8px;
    max-width: 168px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

svg._3hclQT {
    width: 36px;
    height: 36px
}

._28e55C {
    text-transform: capitalize;
    cursor: pointer
}

._2diFTa {
    margin-left: 2.1875rem;
    cursor: pointer
}

._2ntEgZ {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._1StS8F {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._1StS8F,
._3-3xVV {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._3-3xVV {
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

._1L0bcb {
    color: #f6a700;
    font-size: .875rem;
    margin-top: 1px;
    text-align: right
}

.CKt1e_ {
    margin-left: 1.25rem;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.SmBzvj {
    font-size: 1.875rem;
    line-height: 1.875rem;
    margin-left: .3125rem;
    color: #ee4d2d
}

._1E2dyV {
    padding: 0 12px 0 20px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    min-width: 58px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

._1E2dyV .stardust-checkbox--disabled {
    opacity: 1
}

._1E2dyV .stardust-checkbox--disabled .stardust-checkbox__box {
    border: 1px solid #e7e7e7
}

._1ri0rT {
    grid-column-start: 1;
    grid-column-end: 4;
    -ms-grid-column-span: 3;
    border-top: 1px dashed rgba(0, 0, 0, .09)
}

._2jol0L {
    display: grid;
    grid-template-columns: 1fr 20.3125rem 11.875rem;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    width: 100%;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 1rem;
    position: relative;
    margin-top: .75rem
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    ._2jol0L {
        z-index: 2;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0
    }
}

._2jol0L .shopee-button-solid {
    padding: 13px 36px;
    margin: 0 22px 0 15px;
    text-transform: capitalize;
    font-weight: 300;
    height: 2.5rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: .875rem;
    border-radius: 2px;
    width: 13.125rem
}

@supports not ((position:-webkit-sticky) or (position:sticky)) {
    .g4OWlx {
        position: fixed;
        left: 50%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 2;
        width: 1200px
    }
}

._3GVi82 {
    border-radius: 0;
    transition: background .1s cubic-bezier(.4, 0, .6, 1)
}

._3GVi82:before {
    content: "";
    position: absolute;
    top: -1.25rem;
    left: 0;
    height: 1.25rem;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, .06))
}

._1vM5hW {
    -ms-grid-row: 1
}

._3c0xgj {
    -ms-grid-row: 2
}

.j1liQm {
    -ms-grid-row: 3
}

._2amAdj {
    -ms-grid-row: 4
}

.zzOmij {
    -ms-grid-row: 5
}

._1nrPtQ {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    overflow: hidden;
    border-radius: 3px;
    height: 55px;
    font-size: 14px;
    background: #fff;
    text-transform: capitalize;
    margin-bottom: 12px;
    color: #888;
    padding: 0 20px
}

._2FoQ0m {
    width: 58px !important;
    padding-right: 18px
}

._1zPSKE,
._2FoQ0m {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

._1zPSKE {
    padding: 0 12px 0 20px;
    min-width: 58px
}

._1zPSKE .stardust-checkbox--disabled {
    opacity: 1
}

._1zPSKE .stardust-checkbox--disabled .stardust-checkbox__box {
    border: 1px solid #e7e7e7
}

._27GGo9 {
    color: rgba(0, 0, 0, .8);
    width: 46.27949%
}

._3hJbyz {
    width: 15.88022%;
    text-align: center
}

._155Uu2 {
    width: 15.4265%;
    text-align: center
}

._10ZkNr {
    width: 10.43557%;
    text-align: center
}

._1coJFb {
    width: 12.70417%;
    text-align: center
}

._1fP0AH {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 37.5rem;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._1g-4gk {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 12.5rem;
    height: 11.1875rem;
    background-image: url(https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/cart/9bdd8040b334d31946f49e36beaf32db.png)
}

.h9wsC4 {
    font-weight: 700;
    font-size: 1.25rem
}

._35zxc9,
.h9wsC4 {
    margin-top: 1.625rem
}

._35zxc9 {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block
}

._35zxc9>.shopee-button-solid {
    padding: .625rem 2.625rem
}

._3SCpTT {
    font-size: 1rem;
    text-transform: capitalize
}

._2tKeYb {
    height: 21rem
}

._2tKeYb ._1g-4gk {
    width: 6.75rem;
    height: 6.125rem
}

._2tKeYb .h9wsC4 {
    color: rgba(0, 0, 0, .4);
    font-size: .875rem;
    line-height: 1rem;
    margin-top: 1.125rem
}

._2tKeYb ._35zxc9 {
    margin-top: 1.0625rem
}

._2Paiqk {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    background-color: #d0011b;
    border-radius: 2px;
    padding: 2px 3px;
    vertical-align: middle;
    line-height: 12px
}

._35837E {
    padding: 0;
    margin-top: -2px
}

._35837E>svg {
    display: block;
    fill: #fff;
    width: 30px;
    height: 15px
}

._3JybdD {
    padding: 0
}

._3JybdD>svg {
    display: block;
    fill: #fff
}

._1-RMiA {
    margin-bottom: 1px
}

._1-RMiA>svg {
    display: block;
    fill: #fff;
    width: 24px;
    height: 11px
}

._2KLJmk,
._3ApheT {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._3ApheT {
    height: 3.75rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, .09);
    padding: 0 20px
}

.-AIs4J {
    border-bottom: 0
}

._2zUYwP {
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._2zUYwP,
._3HHh5A {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._3HHh5A {
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    min-width: 58px;
    -moz-box-sizing: border-box;
    width: 58px !important;
    flex-direction: row-reverse;
    box-sizing: border-box;
    padding: 0 18px 0 20px
}

._3HHh5A .stardust-checkbox--disabled {
    opacity: 1
}

._3HHh5A .stardust-checkbox--disabled .stardust-checkbox__box {
    border: 1px solid #e7e7e7
}

._3hbQ-Y {
    margin-left: 8px;
    padding: 3px 10px;
    line-height: 18px;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 11px;
    color: #fff
}

._13iGI_ {
    margin-left: 5px;
    background: none;
    border: 0;
    padding: 0
}

._13iGI_,
.eQP4g3 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

.eQP4g3 {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    font-weight: 500;
    min-width: 50px;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.eQP4g3>._2PqWq9 {
    width: 1.875rem
}

._2KYoW7 {
    color: #ee4d2d;
    width: 1.375rem;
    height: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

._10IR5M {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._3W4omU {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 20px;
    text-transform: capitalize
}

.lkTya6 {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: .625rem;
    fill: none
}

._1CXksa {
    margin-right: 10px
}

._1CXksa:last-child {
    margin: 0
}

._1k1Vcm {
    color: rgba(0, 0, 0, .54);
    text-decoration: line-through
}

.Gk60Vi {
    margin: 0
}

.it5hZ9 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._1crlYq {
    color: rgba(0, 0, 0, .54);
    margin-top: 5px;
    text-transform: capitalize
}

.product-variation {
    cursor: pointer;
    min-width: 5rem;
    min-height: 2.125rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: .25rem .75rem;
    margin: 0 8px 8px 0;
    color: rgba(0, 0, 0, .8);
    text-align: left;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .09);
    position: relative;
    background: #fff;
    outline: 0;
    word-break: break-word;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.product-variation:hover {
    color: #ee4d2d;
    color: var(--brand-primary-color, #ee4d2d);
    border-color: #ee4d2d;
    border-color: var(--brand-primary-color, #ee4d2d)
}

.product-variation--disabled {
    color: rgba(0, 0, 0, .26);
    cursor: not-allowed
}

.product-variation--disabled:hover {
    border-color: rgba(0, 0, 0, .09);
    color: rgba(0, 0, 0, .26)
}

.product-variation--disabled:focus {
    outline: 0
}

.product-variation--selected {
    color: #ee4d2d;
    color: var(--brand-primary-color, #ee4d2d);
    border-color: #ee4d2d;
    border-color: var(--brand-primary-color, #ee4d2d)
}

.product-variation__tick {
    width: .9375rem;
    height: .9375rem;
    position: absolute;
    overflow: hidden;
    right: 0;
    bottom: 0
}

.product-variation__tick>.icon-tick-bold {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    font-size: 8px
}

.product-variation__tick:before {
    border: .9375rem solid transparent;
    border-bottom: .9375rem solid var(--brand-primary-color, #ee4d2d);
    content: "";
    position: absolute;
    right: -.9375rem;
    bottom: 0
}

.shopee-arrow-box__arrow {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 10px
}

.shopee-arrow-box__arrow--left {
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 15px
}

.shopee-arrow-box__arrow--center {
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.shopee-arrow-box__arrow--right {
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 15px
}

.shopee-arrow-box__arrow-outer {
    position: relative;
    bottom: -1px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, .09)
}

.shopee-arrow-box__arrow-inner {
    position: absolute;
    bottom: -10px;
    left: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff
}

.shopee-arrow-box__content {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, .09);
    white-space: pre-wrap
}

._3b-8ro {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    outline: none;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._2F1_yL {
    cursor: auto
}

.ns42ir {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: rgba(0, 0, 0, .54);
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: .875rem;
    margin-right: 10px
}

.aXmvTj,
.ns42ir {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

.aXmvTj {
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: capitalize;
    text-align: left
}

._2Ipt-j {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background: none;
    padding: 0;
    border-top: 5px solid rgba(0, 0, 0, .54);
    border-bottom: 0;
    transition: -webkit-transform .1s cubic-bezier(.4, 0, .6, 1);
    transition: transform .1s cubic-bezier(.4, 0, .6, 1);
    transition: transform .1s cubic-bezier(.4, 0, .6, 1), -webkit-transform .1s cubic-bezier(.4, 0, .6, 1);
    -webkit-transform: rotate(0);
    transform: rotate(0);
    margin: 0 0 0 10px
}

._1h_AJa {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.GU_XoN {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;
    margin-top: 5px
}

.xVCpY7 {
    position: absolute;
    top: 110%;
    right: -1.25rem;
    z-index: 3
}

.xVCpY7 .shopee-arrow-box__arrow-outer {
    border-bottom-color: rgba(0, 0, 0, .09)
}

.xVCpY7 .shopee-arrow-box__content {
    border-color: rgba(0, 0, 0, .09);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .09)
}

.sgHDOS {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 27px 25px;
    width: 19.6875rem;
    max-height: 28.125rem;
    overflow-y: auto;
    background: #fff
}

.-wGaUX,
._2QIzsY {
    text-transform: capitalize;
    color: rgba(0, 0, 0, .54);
    font-size: 1rem
}

.-wGaUX,
._2QIzsY {
    margin-bottom: 10px
}

._2dGEqA {
    margin-bottom: 15px
}

._2dGEqA:last-child {
    margin-bottom: 0
}

._3VArGP {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

._1_zHs0,
._3VArGP {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._1_zHs0 {
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 5px;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._1_zHs0>.shopee-button-solid {
    width: 140px;
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 500;
    padding: 12px 0;
    cursor: pointer
}

.xN6cOD {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._3curzV {
    padding: .125rem .25rem;
    margin: 0 .3125rem .3125rem 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #ee4d2d;
    border-radius: .125rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 400;
    font-size: .625rem;
    line-height: .75rem;
    color: #ee4d2d
}

._2u-tNk {
    overflow: visible
}

._2sBbZC {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1);
    border: 1px solid rgba(0, 0, 0, .09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0, 0, 0, .8);
    width: 32px;
    height: 32px
}

.-JpOAg,
._2sBbZC[disabled] {
    border-color: rgba(0, 0, 0, .09);
    color: #ccc
}

.-JpOAg svg,
._2sBbZC[disabled] svg {
    fill: #ccc
}

._2sBbZC .shopee-svg-icon {
    font-size: 10px;
    width: 10px;
    height: 10px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

._2sBbZC:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

._2sBbZC:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.eLoUcd {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.k-s4Da {
    width: 50px;
    height: 32px;
    border-left: 0;
    border-right: 0;
    font-size: 16px;
    font-weight: 400;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: text;
    border-radius: 0;
    -webkit-appearance: none
}

._2ZUrV7 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 15.4265%
}

._2ZUrV7 .shopee-input-quantity>.shopee-button-outline {
    width: 30px;
    height: 30px
}

._2ZUrV7 .shopee-input-quantity>.shopee-button-outline-mid {
    width: 40px
}

._1Z18Vz {
    opacity: .3
}

._1hk8u6 {
    margin-top: .3125rem;
    color: #ee4d2d;
    font-size: .8125rem
}

._1WRTWo {
    margin: 4px 0 2px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    text-transform: capitalize
}

.Epo_Se {
    color: #ee4d2d;
    margin-right: 5px;
    font-size: 12px;
    line-height: 14px
}

._1ooyNC {
    background-color: #ee4d2d;
    font-size: 10px;
    border-radius: 1px
}

._1ooyNC,
._3TMn08 {
    color: #fff;
    padding: 1px 5px
}

._3TMn08 {
    border-radius: 44px;
    background-color: #a3a3a3;
    height: 14px;
    line-height: 13px;
    text-transform: uppercase;
    font-size: .625rem;
    margin-right: 5px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

._216OLk {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block;
    padding: 0 20px 15px;
    margin-top: 15px
}

._216OLk:first-child {
    padding-top: 15px
}

._216OLk:last-child {
    padding-bottom: 20px
}

._1GcTXp {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._2hZzsu {
    border-top: 0
}

._2hZzsu .dn3H7Y {
    color: rgba(0, 0, 0, .8)
}

._2hZzsu ._2pPbjQ,
._2hZzsu ._30hIFE,
._2hZzsu .dn3H7Y,
._2hZzsu .Ra8lP2 {
    opacity: .3
}

._3SIEad {
    background: rgba(0, 0, 0, .02)
}

._2V6qpG {
    margin-top: 0 !important;
    padding-top: 15px;
    background: rgba(238, 77, 45, .05) !important
}

.MDvvnL {
    margin-top: 0;
    padding: 16px 0
}

._1ocTND {
    background-color: rgba(255, 243, 237, .4);
    border-radius: 2px
}

._2aRlry {
    margin-left: 50px;
    margin-right: 20px;
    height: 1px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, .09)
}

._2aRlry:last-child {
    display: none
}

.uUhc_B {
    padding: 0 12px 0 20px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    min-width: 58px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.uUhc_B .stardust-checkbox--disabled {
    opacity: 1
}

.uUhc_B .stardust-checkbox--disabled .stardust-checkbox__box {
    border: 1px solid #e7e7e7
}

._2pPbjQ {
    width: 29.03811%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    opacity: .3
}

._2pPbjQ,
.YxpsCR {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._30hIFE {
    width: 17.24138%
}

._30hIFE,
.Ra8lP2 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.Ra8lP2 {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 15.88022%
}

.dn3H7Y {
    width: 10.43557%;
    text-align: right;
    color: #ee4d2d
}

._2y8iJi {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 12.70417%;
    text-transform: capitalize
}

._2qPRqW {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.RCd1Gx {
    cursor: pointer;
    background: none;
    border: 0
}

.RCd1Gx:hover {
    color: #ee4d2d;
    transition: color .1s cubic-bezier(.4, 0, .6, 1)
}

.eJDMsz {
    margin-top: 5px;
    color: #ee4d2d;
    font-size: 13px
}

._3tHnvR {
    margin-top: 32px;
    line-height: 18px;
    text-align: right;
    padding-right: 48px
}

._3DpFZR,
._3tHnvR {
    font-size: 14px;
    color: #f63336
}

._3DpFZR {
    margin-top: 11px;
    margin-bottom: 26px;
    line-height: 14px;
    text-align: left;
    padding-left: 58px
}

._1Q0LFX {
    margin-top: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ee4d2d;
    display: inline-block
}

._22yEU3 {
    vertical-align: bottom;
    margin-right: 6px;
    height: 14px;
    width: auto
}

._eOgSl {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ee4d2d
}

._eOgSl>p {
    display: inline
}

._3r3ZCy {
    margin-top: auto;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._2w46K6 {
    width: 15.6px;
    height: 14px
}

._1AXYLY {
    font-size: 12px;
    color: #252525;
    margin-left: 5px
}

._3SWf-5 {
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px
}

._3OrWGt {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 5px 20px 0 10px;
    font-size: 14px;
    width: 243.5px
}

._2fQT1K,
._3OrWGt {
    line-height: 16px;
    overflow: hidden
}

._2fQT1K {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block;
    margin-bottom: 5px;
    max-height: 32px;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}

.Uow98x {
    line-height: 16px;
    padding: 0 1px;
    margin-right: .5em;
    color: #ee4d2d;
    border-radius: 2px;
    font-size: 10px;
    border: 1px solid
}

._931iK8 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: #ee4d2d
}

.Vp8wgr {
    margin-top: 0;
    padding-top: 15px;
    background: rgba(238, 77, 45, .05)
}

._2o95Vf {
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat
}

._1-rOD0 {
    z-index: 2;
    max-width: 100%
}

._3ShG-i {
    z-index: 3
}

.rcMXLm {
    position: absolute;
    margin-top: 1.8125rem;
    left: 0;
    width: 75rem;
    max-width: 1200px
}

.NJSFiA {
    color: #ee4d2d;
    font-weight: 400;
    border: .0625rem solid transparent;
    height: 1.875rem;
    max-width: 100%
}

.v3VClT {
    position: relative;
    width: 8.125rem;
    border: .0625rem solid #ee4d2d;
    border-bottom: 0;
    border-radius: .0625rem .0625rem 0 0;
    background-color: #fff
}

.v3VClT ._16a080 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

._27CqwY {
    margin-left: .25rem;
    width: .625rem
}

._1QQSN1 {
    padding: .75rem 1.25rem .3125rem
}

._1QQSN1:last-of-type {
    padding-bottom: .9375rem
}

._3bDk5g {
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

._1wrtpl,
._3bDk5g {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._1wrtpl {
    width: 34.30127%;
    padding: 0 0 0 80px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.qMkW-R {
    padding: 0 0 0 12px
}

._2KQrOb {
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 3.125rem;
    min-height: 3.125rem;
    margin: 0 0 0 .4375rem
}

._2qA1Rd {
    padding: .3125rem 1.25rem 0 .625rem;
    font-size: .875rem;
    line-height: 1rem;
    width: 15.21875rem;
    color: rgba(0, 0, 0, .8)
}

._15nkEc {
    line-height: 1rem;
    padding: 0 1px;
    margin-right: .5em;
    color: #ee4d2d;
    border-radius: 2px;
    font-size: .625rem;
    border: 1px solid;
    white-space: nowrap
}

._2V42c4,
._15nkEc {
    vertical-align: middle
}

.OJ6lyA {
    width: 17.24138%;
    padding: .3125rem 0 0;
    color: rgba(0, 0, 0, .54)
}

._1AUy_v {
    width: 15.88022%
}

._1rgxfa {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 15.4265%;
    padding: .3125rem 0 0
}

._392CT0 {
    width: 10.43557%
}

._1qo1kh {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 12.70417%;
    text-transform: capitalize;
    padding: .3125rem 0 0
}

._3nmYjL {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._1I-y2y {
    font-size: .75rem;
    line-height: 1rem;
    text-align: right;
    padding-right: .75rem;
    color: #f63336
}

.IMcDWt {
    text-align: left;
    padding-left: 3.625rem;
    margin-bottom: 1.625rem;
    font-size: .875rem
}

._3iAzMu {
    background: rgba(238, 77, 45, .05) !important;
    padding-bottom: 1rem
}

.qz064s ._1AUy_v,
.qz064s ._1rgxfa,
.qz064s ._1wrtpl,
.qz064s ._392CT0,
.qz064s .OJ6lyA {
    opacity: .26
}

._3iboHP {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.ZKhvKK {
    white-space: nowrap;
    padding: 0 .5rem;
    height: 1.0625rem;
    font-size: .625rem;
    line-height: 1.25rem;
    background: rgba(0, 0, 0, .4);
    border-radius: 2px;
    color: #fff;
    text-transform: uppercase
}

._2oMVxJ {
    cursor: pointer;
    background: none;
    border: 0
}

._2oMVxJ:hover {
    color: #ee4d2d;
    transition: color .1s cubic-bezier(.4, 0, .6, 1)
}

.r2jApx {
    border: 1px solid rgba(0, 0, 0, .09);
    border-radius: 2px;
    margin: 22px 20px
}

._1NwFL3 {
    padding: 9px 12px;
    background: #fff3ed;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, .87)
}

._319wph {
    padding: 1px 4px;
    margin-right: 8px;
    line-height: 12px;
    vertical-align: middle;
    color: #ee4d2d;
    font-size: 10px;
    border: 1px solid;
    border-radius: 2px;
    font-weight: 400
}

.NV5IY8 {
    margin-left: 12px
}

.NV5IY8>a {
    display: inline-block;
    color: #ee4d2d;
    text-transform: capitalize
}

._1KsfYG {
    vertical-align: -1px
}

._23P_3b {
    padding: 1rem 0 1rem 2.5rem;
    border-top: 1px solid rgba(0, 0, 0, .09)
}

._2XZJ-N,
._23P_3b {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._2XZJ-N {
    margin: 0 0 0 .875rem;
    height: 1.75rem
}

._2Iugz_,
._3m_0nP {
    color: #ee4d2d;
    fill: currentColor;
    width: 1.3125rem;
    height: 1.25rem;
    margin-top: .0625rem
}

._2Whyx_ {
    position: relative;
    z-index: 0;
    margin: 0 0 0 1.5rem
}

._2Whyx_ .stardust-spinner {
    padding: .9375rem 0
}

._2Whyx_ .shopee-popover {
    width: 36.25rem;
    margin: .625rem 0 0
}

._2Whyx_ .shopee-popover:after,
._2Whyx_ .shopee-popover:before {
    display: none
}

._2Whyx_ .shopee-popover .voucher-ticket {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    white-space: normal;
    text-align: center
}

._2uSgOy {
    white-space: nowrap;
    color: #05a
}

._2uSgOy:hover {
    cursor: pointer
}

._2lXACG {
    z-index: 1
}

._1IYIYG {
    border: 1px solid rgba(0, 0, 0, .09);
    z-index: 590 !important
}

._1IYIYG:after,
._1IYIYG:before {
    content: "";
    position: absolute;
    left: 4rem
}

._1IYIYG:after {
    border: .5rem solid transparent;
    border-bottom-color: #fff;
    top: -.9375rem;
    z-index: 1
}

._1IYIYG:before {
    border: .5rem solid transparent;
    border-bottom-color: rgba(0, 0, 0, .09);
    top: -1.0625rem;
    z-index: 0
}

@-webkit-keyframes movement {
    0% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }

    50% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px)
    }

    to {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }
}

@keyframes movement {
    0% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }

    50% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px)
    }

    to {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }
}

.stardust-spinner--hidden {
    display: none
}

.stardust-spinner__background {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, .4);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.stardust-spinner__background--no-overlay {
    position: static;
    background-color: transparent;
    width: auto;
    height: auto
}

.stardust-spinner__background--no-overlay .stardust-spinner__main {
    padding: 0;
    border-radius: 0
}

.stardust-spinner__main {
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding: 1rem;
    border-radius: 1rem;
    background: #fff;
    color: #000
}

.stardust-spinner__spinner {
    -webkit-animation: movement .4s linear infinite;
    animation: movement .4s linear infinite
}

.stardust-spinner__spinner:nth-child(2) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.stardust-spinner__spinner:nth-child(3) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

._1YXfex {
    color: rgba(0, 0, 0, .87)
}

._1qbSuE {
    color: #ee4d2d
}

.SrJuPg {
    color: rgba(0, 0, 0, .54)
}

.SrJuPg>.stardust-spinner {
    display: inline-block;
    margin: 0 0 0 .25rem;
    -webkit-transform: scale(.3) translate3d(0, -.5rem, 0);
    transform: scale(.3) translate3d(0, -.5rem, 0);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left
}

._3auW1H {
    color: rgba(0, 0, 0, .26)
}

._1NMIzx {
    margin: 0 0 0 1.5rem
}

._1NMIzx button {
    background: #fff !important;
    color: #ee4d2d !important;
    border: .0625rem solid #ee4d2d !important
}

._1NMIzx button:active,
._1NMIzx button:focus {
    box-shadow: inset 0 .125rem .0625rem 0 rgba(0, 0, 0, .05)
}

._1GJ5Rt button {
    border: 0 !important
}

.E4gfyF {
    background-color: #ee4d2d;
    color: #fff;
    text-transform: capitalize;
    padding: .125rem .25rem;
    margin: 0 .1875rem 0 .25rem;
    font-size: .75rem;
    border-radius: 1px
}

._1zYZD1 {
    background-color: rgba(0, 0, 0, .26)
}

.shopee-drawer {
    position: relative;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

.shopee-drawer__contents {
    position: absolute;
    top: 100%;
    z-index: 400
}

.shopee-drawer .icon-help {
    stroke: currentColor
}

.shopee-drawer-image-show__content-wrapper,
.shopee-drawer-image-show__image {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.shopee-drawer-image-show__content-wrapper {
    position: relative
}

.shopee-drawer-image-show__find-button {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    bottom: .3125rem;
    right: .3125rem;
    height: 2.1875rem;
    width: 2.1875rem;
    background: #fff
}

.shopee-drawer-image-show__find-button>svg {
    font-size: 1.5625rem;
    stroke: currentColor
}

._2oaKY4 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.25rem 0 1.25rem 2.5rem;
    border-top: 1px solid rgba(0, 0, 0, .09)
}

._2oaKY4 .shopee-drawer:hover .shopee-drawer__contents:after {
    visibility: visible
}

._2oaKY4 .shopee-drawer__contents {
    top: calc(100% + .625rem)
}

._2oaKY4 .shopee-drawer__contents:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: -1.25rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: .625rem .75rem;
    visibility: hidden;
    transition: visibility 0s;
    transition-delay: .2s;
    z-index: 3
}

._1PmfJ2 {
    margin-left: .9375rem
}

._1m76cP {
    color: #00bfa5;
    stroke: currentColor;
    fill: currentColor;
    width: 1.25rem;
    height: .9375rem
}

._1UZAeJ {
    margin-left: .375rem;
    color: #05a;
    cursor: pointer
}

._3GXko3 {
    box-shadow: 0 .0625rem 3.125rem 0 rgba(0, 0, 0, .14);
    background-color: #fff;
    width: 31.25rem;
    border-radius: 2px;
    border-top: 0;
    overflow: hidden;
    padding: 1.75rem 1.875rem;
    white-space: normal
}

._1xNErv,
.gGJyRj {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

._1xNErv {
    margin-top: .9375rem;
    font-size: 1rem
}

._1D2MbG {
    color: #888
}

._2drWlR {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

._1uFxN5 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._1zVQrT {
    margin-top: .9375rem
}

._3HtYVZ {
    display: inline-block;
    padding: .125rem .3125rem;
    border: 1px solid #ee4d2d;
    font-size: 10px;
    line-height: 12px;
    color: #ee4d2d;
    border-radius: 1px
}

._3HtYVZ+._3HtYVZ {
    margin-left: .5em
}

._3HHi4G {
    font-size: 1.25rem
}

._32zkot {
    font-size: 1rem;
    color: #bbb
}

._1-iN2J {
    width: calc(100% - 15px);
    border-top: 1px solid rgba(0, 0, 0, .09);
    margin-left: .9375rem
}

._1-iN2J,
.aCSbhb {
    margin-bottom: .9375rem
}

.aCSbhb {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    overflow: hidden;
    background: #fff;
    overflow: visible
}

._2qN5oG {
    position: relative;
    padding-bottom: 1px
}

._3BFLyT {
    background: rgba(238, 77, 45, .05) !important;
    margin-top: -1px;
    border-top: 1px solid rgba(0, 0, 0, .09);
    padding: .9375rem 3rem .9375rem 0;
    font-size: .875rem;
    line-height: 1.125rem;
    text-align: right;
    color: #f63336
}

._241w9O {
    margin-bottom: 0
}

._37mcnl {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    overflow: hidden;
    background: #fff;
    margin-bottom: .9375rem;
    overflow: visible;
    min-height: 200px
}

.zGYtqD {
    height: 3.75rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, .09);
    padding: 0 1.25rem
}

._3kTSYR,
.zGYtqD {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

._3kTSYR {
    padding: 1.5625rem 0 1.25rem 2.5rem;
    border-top: 1px solid rgba(0, 0, 0, .09)
}

.PFcqG2 {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 1.25rem .9375rem;
    margin-top: .9375rem
}

.PFcqG2:first-child {
    padding-top: .9375rem
}

.PFcqG2:last-child {
    padding-bottom: 1.25rem
}

._3IGI7- {
    width: calc(100% - 15px);
    border-top: 1px solid rgba(0, 0, 0, .09);
    margin-bottom: .9375rem;
    margin-left: .9375rem
}

._1ka-56 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

._1ka-56 ._1oPjl5:first-child {
    margin-bottom: .375rem
}

._2anfV4 {
    padding: 0 12px 0 20px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    min-width: 58px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

._2anfV4 .stardust-checkbox--disabled {
    opacity: 1
}

._2anfV4 .stardust-checkbox--disabled .stardust-checkbox__box {
    border: 1px solid #e7e7e7
}

.FurPOw {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: #f4f4f4;
    margin-right: .5rem;
    -webkit-animation: placholderPulse 1.5s ease-in-out infinite;
    animation: placholderPulse 1.5s ease-in-out infinite
}

._3gzagk {
    height: 2rem;
    width: 2rem;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54 61' fill='%23e5e4e4'%3E%3Cpath d='M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z'/%3E%3C/svg%3E")
}

._1oPjl5 {
    background-color: #f4f4f4;
    height: .875rem;
    -webkit-animation: placholderPulse 1.5s ease-in-out infinite;
    animation: placholderPulse 1.5s ease-in-out infinite
}

._2an_Hm {
    width: 27.8125rem
}

.WmyLE_ {
    width: 22.375rem
}

._2-OWf6 {
    width: 13.0625rem
}

@-webkit-keyframes placholderPulse {
    0% {
        background-color: #f4f4f4
    }

    50% {
        background-color: #f9f9f9
    }

    to {
        background-color: #f4f4f4
    }
}

@keyframes placholderPulse {
    0% {
        background-color: #f4f4f4
    }

    50% {
        background-color: #f9f9f9
    }

    to {
        background-color: #f4f4f4
    }
}

._3Spavd {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    overflow: hidden;
    overflow: visible;
    background: #fff;
    padding: 0 20px;
    position: relative
}

._3Cqbm9 {
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, .09)
}

.XI2hJT {
    padding: 24px 58px;
    text-transform: capitalize;
    font-weight: 500
}

.f0rDtw {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, .09);
    outline: none;
    padding: 18px 0;
    cursor: pointer;
    width: 100%
}

._3K8nAY {
    margin-top: .5rem;
    margin-left: -.1875rem;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .26);
    border-radius: 2px;
    overflow: hidden
}

._2Hn3yX {
    color: rgba(0, 0, 0, .26);
    font-size: .875rem;
    line-height: 1rem;
    padding: .625rem
}

.shopee-searchbar {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 2.5rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: .1875rem;
    border-radius: 2px;
    background: #fff
}

.shopee-searchbar--focus {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .09)
}

.shopee-searchbar>.btn svg {
    margin: 0 auto
}

.shopee-searchbar-input,
.shopee-searchbar__main {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.shopee-searchbar-input {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: .625rem
}

.shopee-searchbar-input__input {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline: none;
    border: 0;
    padding: 0;
    margin: 0
}

.shopee-searchbar-input__input:-moz-placeholder {
    color: rgba(0, 0, 0, .54)
}

.shopee-searchbar-input__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, .54)
}

.shopee-searchbar-input__input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .54)
}

.shopee-searchbar-input__input::-moz-placeholder {
    color: rgba(0, 0, 0, .54)
}

.shopee-searchbar-hints {
    margin-top: .5rem;
    margin-left: -.1875rem;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .26);
    border-radius: 2px;
    overflow: hidden
}

.shopee-searchbar-hints__header {
    color: rgba(0, 0, 0, .26);
    font-size: .875rem;
    line-height: 1rem;
    padding: .625rem
}

.shopee-searchbar-hints__history-entry {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block;
    padding: .625rem;
    font-size: .875rem;
    line-height: 1rem;
    color: rgba(0, 0, 0, .8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.shopee-searchbar-hints__history-entry:hover {
    background-color: #fafafa
}

.shopee-searchbar-hints__history-entry__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.shopee-searchbar-hints__history-entry__typed-text {
    font-weight: 500
}

.shopee-searchbar-hints__history-entry__category-name {
    color: rgba(0, 0, 0, .26);
    margin-top: .25rem
}

.shopee-searchbar-hints__entry,
.shopee-searchbar-hints__search-prefill,
.shopee-searchbar-hints__search-shop {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1rem;
    padding: .625rem
}

.shopee-searchbar-hints__entry:hover,
.shopee-searchbar-hints__search-prefill:hover,
.shopee-searchbar-hints__search-shop:hover {
    background-color: #fafafa
}

.shopee-searchbar-hints__entry__bold-product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.shopee-searchbar-hints__search-shop {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.shopee-searchbar-hints__search-shop .shopee-svg-icon {
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
    color: #ee4d2d
}

.shopee-searchbar-hints__entry {
    overflow: hidden;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.shopee-searchbar-hints__entry--history {
    text-overflow: ellipsis
}

.shopee-searchbar-hints__entry__product-name {
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto
}

.shopee-searchbar-hints__entry__typed-product-name {
    font-weight: 500
}

.shopee-searchbar-hints__entry__category-name {
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    color: rgba(0, 0, 0, .26);
    margin-top: .25rem
}

.shopee-searchbar-hints__search-prefill {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 15px
}

.shopee-searchbar-hints__search-prefill img {
    margin-left: auto
}

.shopee-searchbar-hints__search-prefill .shopee-searchbar-hints__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px
}

.shopee-searchbar-user-hints {
    background-color: #fff;
    border-left: 1px solid rgba(0, 0, 0, .09);
    border-right: 1px solid rgba(0, 0, 0, .09);
    border-bottom: 1px solid rgba(0, 0, 0, .09)
}

.shopee-searchbar-user-hints__entry {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: .625rem;
    font-size: .875rem;
    line-height: 1rem
}

.shopee-searchbar-selector {
    position: relative;
    max-width: 9.375rem;
    height: 100%;
    color: rgba(0, 0, 0, .87);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.shopee-searchbar-selector .shopee-drawer {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.shopee-searchbar-selector .shopee-drawer__contents {
    z-index: 601
}

.shopee-searchbar-selector__selected {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-left: 1px solid rgba(0, 0, 0, .09);
    height: 75%;
    padding-left: .9375rem;
    padding-right: .625rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.shopee-searchbar-selector__selected>.shopee-svg-icon {
    width: .5rem;
    height: .5rem;
    margin-left: .9375rem
}

.shopee-searchbar-selector__selected-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.25rem
}

.shopee-searchbar-selector__options {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    overflow: hidden;
    background-color: #fff;
    margin-top: .5rem
}

.shopee-searchbar-selector__option,
.shopee-searchbar-selector__options {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

.shopee-searchbar-selector__option {
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    height: 2.8125rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: .9375rem;
    padding-right: .625rem;
    white-space: nowrap
}

.shopee-searchbar-selector__option:hover {
    background-color: #fbfbfb
}

.shopee-searchbar-selector__option-label {
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.shopee-searchbar-selector__option-tick {
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    color: #ee4d2d;
    width: .625rem;
    height: .625rem;
    margin-left: .9375rem;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center
}

.cart-page-header-wrapper {
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .09);
    height: 6.25rem
}

.cart-page-header,
.cart-page-header-wrapper {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
}

.cart-page-header {
    padding-left: 1.125rem;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.cart-page-header .cart-page-searchbar>.searchbar {
    border: 2px solid #ee4d2d
}

.cart-page-logo {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.cart-page-logo__page-name {
    margin-left: .9375rem;
    border-left: .0625rem solid #ee4d2d;
    color: #ee4d2d;
    font-size: 1.25rem;
    line-height: 1.875rem;
    height: 1.875rem;
    padding-left: .9375rem;
    margin-bottom: .0625rem;
    text-transform: capitalize
}

.cart-page-header--tw .cart-page-logo__page-name {
    margin-bottom: .125rem
}

.cart-page-logo .icon-shopee-logo {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 8.125rem;
    height: 2.875rem;
    cursor: pointer;
    fill: #ee4d2d
}

.cart-page-searchbar {
    width: 38.8125rem;
    position: relative
}

.cart-page-searchbar>.shopee-searchbar {
    padding: 0;
    border: 2px solid #ee4d2d
}

.cart-page-searchbar button {
    height: unset;
    border-radius: 0;
    width: 5rem
}

._1nft6V {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: rgba(0, 0, 0, .8);
    padding-top: 20px
}

.wI3_X8 {
    width: 100%;
    height: 12px
}