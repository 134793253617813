@import "../../theme/color.css";
html {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  /* card-list */
  body {
    --spacing: 10px;
    --columns: 6;
    --subtitle: 13px;
  }
  button:focus{
    box-shadow: none !important;
  }
  input:focus{
    box-shadow: none !important;
    
  }
  .path-link a{
    text-decoration: none; 
  }
  .path-link a:hover{
    transform: scale(1.05,1.05);
    transition: transform 0.3 ease;
    font-weight: bold;
  }
  /* br{
    margin: 15px;
  } */
  /* .product-container-saleplus{
    min-width: 100%;
  } */
  .shop-view-container{
    min-width: 1150px;
  }
  .card-course {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    box-shadow: rgba(31, 31, 32, 0.2) 0px 7px 29px 0px;
    margin-right: -0.5rem;
    margin-bottom: 0.7rem;
    transform: scale(1,1);
    transition: transform 0.3s ease;
    /* width: 19%; */
  }
  .card-course:hover {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    box-shadow: rgba(31, 31, 32, 0.2) 0px 7px 29px 0px;
    margin-right: -0.5rem;
    margin-bottom: 0.7rem;
    transform: scale(1.04 ,1.04);
    transition: transform 0.3s ease;
    cursor: pointer;
    /* width: 19%; */
  }
  .card-img-top {
    object-fit: cover;
    height:150px;
  }
  .card-top {
      padding-bottom: 1rem;
      height: 60px;
      position: relative;
      display: flex;
      justify-content: center;
  }
  .card-star{
    width: fit-content;
    height: fit-content;
    color: white;
    padding-right: 3px;
    position: absolute;
    /* left: 40%; */
    top: -45%;
    border: 3px solid #ffffff;
    border-radius: 100rem;
    color: #111111;
    /*background-color: #9756F6; */
  
  }
  .card-product-name {
    margin-top: -3px;
    font-weight: bold;
    color: black;
    background-color: white;
    white-space: pre-wrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
     display: -webkit-box;
     height: fit-content
    
  }
  /* .card-product-name:hover{
    white-space: normal; 
    overflow: visible;
    word-break: break-word;
  } */
  /* .card_course_name{
    height: 60px;
  } */
  .card-user {
    display: flex;
    align-items: center;
  }
  .card-user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100rem;
    object-fit: cover;
  }
  .card-user-info {
    padding-left: 20px;
    flex: 1;
  }
  .card-user-top {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .card-user-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    padding-top: 7px;
  }
  #popovers-icon {
    margin-top: 1.5rem;
  }
  .popover-header {
    background-color: white;
    background-image: linear-gradient(to bottom right, #00aefd, #ffa400);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .card-user-top ion-icon {
    color: #20e3b2;
    margin-left: 5px;
  }
  .card-bottom {
    padding: 5px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-live {
    color: white;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    background-color: #f06957;
    margin-right: 10px;
  }
  .card-live span {
    font-size: 14px;
  }
  .card-watching {
    font-size: 13px;
    font-weight: 300;
  }
  .card-time {
    font-weight: 300;
    font-size: var(--subtitle);
  }
  .row-title {
    margin: 5px ;
    width:fit-content;
  }

  .shop-infor-description{
    max-width: 400px;
  }
  .component-top{
    justify-content: space-between;
  }
  .component-top-title{
    /* background-image: linear-gradient(to bottom right, #00aefd, #ffa400);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent; */
      text-align: center;
      font-weight: bold;
      font-size: 20px;

  }
  .component-top-button {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .link__to{
    color: white;
  }
  .btn-sort{
    width: "fit-content";
    margin: 5px;
    height: fit-content;
  }
  
  .btn-sort:focus{
    box-shadow: none;
    
  }
  .btn-sort:hover{
    color: #F69756;
  }
  .select-sort{
    border: 1px solid black;
    height: fit-content;
  }
  .select-sort:focus{
    box-shadow: none;
    border: 1px solid black;
  }
  .sort-card{
    display: flex;
    justify-content: space-between;
    flex-direction:row;
    background-color: rgb(243, 243, 243);
    height:fit-content;
  }
  .card-shop-intro{
    width:80%;
    height: 150px;
    margin-left: 15%;
    margin-bottom: 5px;
    object-fit: cover;
    border-radius: 10px;
    /* background: url("https://images.unsplash.com/photo-1550795629-4350bf35d102?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=375&q=80"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.card-body-shop{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sort-star-img{
  width: 15px;
}
.rate-button{
  height: 25px;
  width: 90px;
  display: flex !important; 
}
.rate-button:focus{
  box-shadow: none;
}
.rate-button:hover{
  background-color: var(--hawkesblue);
}
.pagin-card{
  height:fit-content;
}
.card-price{
  justify-content: space-between;
}
.product-price{
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  /* -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
   display: -webkit-box; */
   height: fit-content
}
.free-ship-img{
  /* margin-left: 2px; */
  width: 25px;
  height:25px
}
.scroller{
  height:700px;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
.collection-filter_collection{
  border-bottom: 1px solid silver;
  margin-top: 5px;
  padding: 3px;
  text-decoration: none;

}
/* .collection-filter_collection:active{
  color: var(--color)
} */
.discount-badge{
  position: absolute;
  padding-left: 3px;
  width: fit-content;
  word-break: break-word;
}



.product-detail-container{
  min-width: 1200px;
}
.info-product-detail-intro div div{
  width: fit-content
}
.share-product-detail-intro{
  display: flex;
  flex-direction: row;
}
.product-product-detail-name{
  font-size: 25px;
  font-weight: bold;
}
.product-product-detail-rate{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.product-product-detail-rate div{
  margin-left: 10px;
  padding-right: 5px;
  border-right: 1px solid black;
}
.product-product-detail-price{
  display: flex;
  align-items: center;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  height: fit-content;
  font-size: 30px;
  color: var(--tanhide);
  background-color: rgb(243, 243, 243);
}
.card-product-detail-title{
  text-align: center;
  font-weight: bold;
  background-color: var(--corvette);
  height: 50px;
  padding-top: 10px;
  /* margin: 0px 2px */
}
.sort-rate-button{
  background-color: white;
  border: 1px solid black;
  transform: scale(1,1);
  width: fit-content;
  margin: 8px;
  color: black;
  padding: 5px;
  transform: scale(1,1);
  transition: transform 0.3s ease;
}
.rate-active{
  border: 2px solid var(--christine);
  color: var(--christine);
  font-weight: bold;
  transform: scale(1.2,1.2);
  transition: transform 0.3s ease;
}
.product-img{
  object-fit: cover;
  
}
/* .intro-img::after{
  position:absolute;
  content: "intro image";
  font-size: 5px;
  height: 10px;
  background-color: var(--tanhide);
}
.intro-img:hover::after{

} */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  color:rgb(126, 120, 120);
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10000000000000;
  width: 400px;
}
.icon-popover{
  display: none; 
  position: absolute;
  left: 0;
  top: 53px;
  color:rgb(255, 255, 255);}

.dropdown-content .product-item-cart {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.button-view-cart:hover{
  color: white;
}

.dropdown-content .product-item-cart:hover {background-color: #f1f1f1;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .icon-popover{display: block}
._2KdYzP {
  outline: none;
  cursor: pointer;
  border: 0;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: background-color .1s cubic-bezier(.4,0,.6,1);
  border: 1px solid rgba(0,0,0,.09);
  border-radius: 2px;
  background: transparent;
  color: rgba(0,0,0,.8);
  width: 32px;
  height: 32px;
}
.iRO3yj {
  width: 50px;
  height: 32px;
  border-left: 0;
  border-right: 0;
  font-size: 16px;
  font-weight: 400;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  cursor: text;
  border-radius: 0;
  -webkit-appearance: none;
}
.product-variation {
  cursor: pointer;
  min-width: 5rem;
  min-height: 2.125rem;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: .25rem .75rem;
  margin: 0 8px 8px 0;
  color: rgba(0,0,0,.8);
  text-align: left;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,.09);
  position: relative;
  background: #fff;
  outline: 0;
  word-break: break-word;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
._2oeDUI {
  -webkit-flex-basis: 32.1875rem;
  -ms-flex-preferred-size: 32.1875rem;
  flex-basis: 32.1875rem;
  max-width: 32.1875rem;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  /* -webkit-align-items: center; */
  -moz-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
}
.flex, .h-center {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.product-variation--selected {
  color: #ee4d2d;
  color: var(--brand-primary-color,#ee4d2d);
  border-color: #ee4d2d;
  border-color: var(--brand-primary-color,#ee4d2d);
}

.button-category {
  border: none;
  background-color: white;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  padding: 2px 10px;
  margin: 1px;
}

.button-category:hover {
  color: #888888;
}