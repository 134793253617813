.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  overflow: hidden;
  width: 100%;
  height: 0%;
  transition: .5s ease;
}

.coverlay-content {
  position: absolute;
  opacity: 1;
}

.overlay-container:hover .overlay {
  height: 40%;
  opacity: .6;
}