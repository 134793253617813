@import '../theme/color.css';
body {
  /* background-image: url('../img/login-bg.jpg'); */
  background-position: top;
}

.login-title {
  color: black;
  font-weight: bold;
  background-image: linear-gradient(to bottom right, var(--flamingo), var(--seabuckthorn));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.input-login {
  padding: 0 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  transition: 0.25s ease;
}

.input-login:focus {
  border-color: #6a5a9f;
  box-shadow: none;
}

.form-field {
  position: relative;
  margin: 15px;
}

.label-login {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 21px;
  user-select: none;
  color: #999;
  pointer-events: none;
  transition: 0.25s ease;
}

.input-login:not(:placeholder-shown)+.label-login, .input-login:focus+.label-login {
  color: #6a5a9f;
  top: 0;
  padding: 4px;
  display: inline-block;
  background-color: #fff;
}

.topnav {
  overflow: hidden;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 5px 5px;
  text-decoration: none;
  font-size: 17px;
}

.signup-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding-top: 90px;
  width: 420px;
  min-height: 470px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 5px;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.267);
}