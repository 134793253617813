.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  border-radius: 0;
}

.w-80vw {
  width: 80vw;
}

.w-70vw {
  width: 70vw;
}

.w-20vw {
  width: 20vw;
}

.minw-80em {
  min-width: 80em;
}

.maxw-15em {
  max-width: 15em;
}

.alert-popup {
  color: #000;
  display: flex;
  padding: 10px 16px;
  /* flex-grow: 1;
  flex-wrap: wrap; */
  font-size: 0.875rem;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* font-weight: 400;
  line-height: 1.43; */
  border: none;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  box-shadow: 5px 5px 10px #aaaaaa;
  background-color: #ffffff;
  min-width: 270px;
}

.link-btn {
  box-shadow: none !important;
  color: rgb(0, 89, 255);
}

.link-btn:hover {
  color: rgb(1, 32, 167);
}
