#root{
    --color: #F69756;
}
/* .shop-view-container{
    min-width: 1200px;
} */
.infor-row{
    border-bottom:  1px solid var(--color);
    margin-bottom: 10px;
    margin-top: 10px;
}
.card-shop-avatar{
    width:80%;
    height: 150px;
    margin-left: 15%;
    margin-bottom: 5px;
    object-fit: cover;
    border-radius: 10px;
    background: url("https://images.unsplash.com/photo-1550795629-4350bf35d102?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=375&q=80");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.button-indicator{
    border-radius: 100rem;
    width:1px;
    height:1px;
    background-color: #F69756;

}
.tab-button{
    font-weight: bold;
    color: black;
    position: relative
}

.tab-button::after{
    position:absolute;
    content: "";
    width:100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: var(--color);
    transform: scale(0,1);
    transition: transform 0.3s ease;
}
.tab-button:hover::after{
    color: var(--color);
    transform: scale(0.95, 1);
}
.active-tab::after{
    transform: scale(0.95, 1);
    color: var(--color);
}
.active-tab{
    color: var(--color);
}
.tab-button:focus{
    box-shadow: none;
}
.video-intro{
    width: 100%;
    height: 100%
}
.carousel-img{
    width: 70%;
    height: 100%
}
.carousel-item{
    width: 70%;
    height: 100% 
}
.icon-button{
    width: 30px;
    height: 30px;
    margin-right: 3px
}
.icon-item{
    width: 20px;
    height: 20px;
    margin-right: 3px
}
.shop-avatar{
    width: 70px;
    height:70px;
    border-radius: 100rem;
    object-fit: cover;
    border: 3px solid silver
}
.shop-infor-description{
    border-left:3px solid var(--color);
}
.row-title-shopview{
    margin-left: 10px;
    margin-bottom: 5%;
    font-weight: bold;
    color:var(--color);
}
.row-shop-name{
    margin-left: 10px;
    color:black;
    font-weight: bold;
}
.row-shop-description{
    margin-left: 10px;
}
.dropdown-item:active{
    color:black
}
.shop-image-description{
    width: 50%;
}
.carousel-intro{
    display:flex;
}
.nav-button{
    position: absolute;
    width: 50px;
    height: 80px;
    justify-content: end;
    top: 150px;
    background-color: transparent;
}
.nav-button:hover{
    background-color: rgb(167, 161, 161);

}