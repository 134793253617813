.pagination{
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5px;
    height:fit-content
}
.page-link {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 3px;
    width: fit-content;
    text-align: center;
  }
/* .page-link:focus {
    background-color: #4CAF50;
    color: white;
} */
.page-link:hover:not(.focus) {
    /* background-color: rgb(131, 128, 128); */
    color: #F69756
}
/* .page-item:active{
    background-color: #F69756 !important;
} */

.selected{
    background-color: #F69756;
}