._3D9BVC {
    position: relative;
    flex-grow: 1;
    width: 980px;
    box-sizing: border-box;
    margin-top: 1em;
    margin-left: 8rem;
    min-width: 0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%);
    border-radius: .125rem;
}

.h4QDlo {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
}

._2YiVnW {
    padding: 0 1.875rem .625rem;
}

._2w2H6X {
    border-bottom: .0625rem solid #efefef;
    padding: 1.125rem 0;
}

._3iiDCN {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-transform: capitalize;
    color: #333;
}

.TQG40c {
    margin-top: .1875rem;
    font-size: .875rem;
    line-height: 1.0625rem;
    color: #555;
}

.goiz2O {
    padding-top: 1.875rem;
    flex-direction: row;
    display: flex;
    align-items: flex-start;
}

.pJout2 {
    flex: 1;
    padding-right: 3.125rem;
}

form {
    display: block;
    margin-top: 0em;
}

._3BlbUs {
    margin-bottom: 1.875rem;
}

._1iNZU3 {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

._2PfA-y {
    width: 20%;
    text-align: right;
    color: rgba(85,85,85,.8);
    text-transform: capitalize;
    overflow: hidden;
}

label {
    cursor: default;
}

._2_JugQ {
    width: 80%;
    box-sizing: border-box;
    padding-left: 1.25rem;
}

.input-with-validator {
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.14);
    box-sizing: border-box;
    box-shadow: inset 0 2px 0 0 rgb(0 0 0 / 2%);
    color: #222;
    height: 40px;
    padding: 10px;
    transition: border-color .1s ease;
}

.input-with-validator input {
    flex: 1;
    font-size: 14px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 0;
}

input {
    line-height: normal;
}

._1iNZU3 {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

._1LvCQN {
    color: rgba(0,0,0,.4);
    margin-top: .3125rem;
    width: 80%;
    box-sizing: border-box;
    padding-left: 1.25rem;
}

._2bdFDW {
    align-items: center;
    display: flex;
}

._3S9myJ {
    font-size: .875rem;
    color: #333;
}

._2CLMxo {
    border: 0;
    outline: none;
    background: none;
    color: #05a;
    font-size: .8125rem;
    text-transform: capitalize;
    text-decoration: underline;
}

._119wWy .stardust-radio-group {
    display: flex;
}

._119wWy .stardust-radio {
    padding: 0;
    margin-right: .75rem;
}

.stardust-radio-button {
    margin-right: 8px;
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
}

.stardust-radio-button__outer-circle {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 2px solid rgba(0,0,0,.26);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.stardust-radio-button__outer-circle>.stardust-radio-button__inner-circle {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #ee4d2d;
    opacity: 0;
}

.stardust-radio__content {
    cursor: pointer;
}

._2w5iZe {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

._2w5iZe .shopee-dropdown {
    width: 32%;
}

._2w5iZe .shopee-dropdown__entry {
    width: 100%;
    box-sizing: border-box;
}

.shopee-dropdown--has-selected .shopee-dropdown__entry--selected {
    color: rgba(0,0,0,.8);
}

.shopee-dropdown__entry--selected {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    height: 2.5rem;
    background: #fff;
}

.shopee-dropdown__entry {
    display: flex;
    align-items: center;
    user-select: none;
    padding: 0 .9375rem;
}

.shopee-dropdown__entry span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
}

.shopee-dropdown__entry .shopee-svg-icon {
    margin-left: .3125rem;
    font-size: .75rem;
}

svg:not(:root) {
    overflow: hidden;
}

.shopee-svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
}

._31PFen {
    margin-bottom: 3.75rem;
    padding-left: calc(20% + 1.25rem);
}

.btn-solid-primary {
    color: #fff;
    background: #ee4d2d;
}

.btn--m {
    height: 40px;
    padding: 0 20px;
    min-width: 70px;
    max-width: 220px;
}

.btn--inline {
    display: inline-flex;
}

.btn {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    flex-direction: column;
    font-size: 14px;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 9%);
    border-radius: 2px;
    border: 0;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer;
}

/*  */

._1aIEbS {
    width: 17.5rem;
    overflow: hidden;
    border-left: .0625rem solid #efefef;
    justify-content: center;
    display: flex;
}

.X1SONv {
    flex-direction: column;
    align-items: center;
    display: flex;
}

._1FzaUZ {
    height: 6.25rem;
    width: 6.25rem;
    margin: 1.25rem 0;
    border: .0625rem solid #ee4d2d;
    position: relative;
}

._1FzaUZ {
    justify-content: center;
    align-items: center;
    display: flex;
}

.TgSfgo {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}


._2xS5eV {
    display: none;
}

input {
    line-height: normal;
    color: inherit;
    font: inherit;
    margin: 0;
}


.btn-light {
    background: #fff;
    color: #555;
    border: 1px solid rgba(0,0,0,.09);
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 3%);
}

.btn--m {
    height: 40px;
    padding: 0 20px;
    min-width: 70px;
    max-width: 220px;
}

.btn--inline {
    display: inline-flex;
}

._3Jd4Zu {
    margin-top: .75rem;
    display: block;
}

._3UgHT6 {
    color: #999;
    font-size: .875rem;
    line-height: 1.25rem;
}

